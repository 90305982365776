/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

@media print {
  .collapse {
    display: block !important;
  }
  .printNone {

    display: none;
   }
  button { display: none;}
  body {
    margin-top: 15mm ;
    margin-left: 10mm;
    margin-right: 0mm;
    margin-bottom: 10mm;
    
  }
  .pageBreak { page-break-before: always;}
}

img {
  max-width: 100%;
}
.sign  img { max-height: 70px; max-width: 150px; ;}


a.nav-link { color: #000}
